import { apiUrl } from '@/../TransitViewerConfig';

function wait(delay) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

function httpMethodRetry(url, delay, attempts, fetchOptions = {}) {
  let triesLeft = attempts;
  function onError(err) {
    triesLeft = attempts - 1;
    if (!triesLeft) {
      throw err;
    }
    return wait(delay).then(() => httpMethodRetry(url, delay, triesLeft, fetchOptions));
  }
  return fetch(url, fetchOptions).catch(onError);
}

async function methodAPI(httpMethod, endpoint, token, options) {
  const defaultOptions = {
    method: httpMethod,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  const requestOptions = { ...defaultOptions, ...options };
  requestOptions.headers = { ...defaultOptions.headers, ...options.headers };
  const response = await httpMethodRetry(endpoint, 1000, 3, requestOptions);
  if (response.status === 401) {
    console.error('Unauthorized. Expired token?');
    window.dispatchEvent(new Event('user-token-expired'));
    return response.json();
  }

  if (response.status === 403) {
    console.error('Forbidden action!');
    return response;
  }

  if (response.status === 409) {
    console.error('Conflict when performing method', httpMethod, 'in endpoint', endpoint);
    return response;
  }

  if (!response.ok && response.status !== 204) {
    throw new Error(`Error fetching data from ${endpoint}: ${response.statusText}`);
  }

  if (options.responseType === 'blob') {
    return response.blob();
  }

  if (response.status === 204) {
    return { isNoContent: true };
  }

  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('text/plain') !== -1) {
    return response.text();
  }
  return response.json();
}

export async function getUrlData(endpoint, token, options = {}) {
  return methodAPI('GET', endpoint, token, options);
}

export async function getAPIData(endpoint, token, options = {}) {
  return getUrlData(`${apiUrl()}${endpoint}`, token, options);
}

export async function postAPIData(endpoint, token, options = {}) {
  return methodAPI('POST', `${apiUrl()}${endpoint}`, token, options);
}

export async function putAPIData(endpoint, token, options = {}) {
  const retvalue = await methodAPI('PUT', `${apiUrl()}${endpoint}`, token, options);
  return retvalue;
}

export async function deleteAPIData(endpoint, token, options = {}) {
  const retvalue = await methodAPI('DELETE', `${apiUrl()}${endpoint}`, token, options);
  return retvalue.isNoContent || retvalue === '';
}
