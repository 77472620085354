<template>
  <div class="bg-white rounded-md w-full flex justify-center items-center sticky top-0 z-10">
    <ToastComponent ref="globalToast" class="mx-auto"/>
    <div v-for="tabObject in tabs" :key="tabObject.name"
      class="text-lg font-medium text-center text-gray-500 dark:text-gray-400 dark:border-gray-700">
      <button v-if="showTabIfPermissionGranted(tabObject.permission)"
      :class="[commonStyle, currentTab === tabObject.name ? selectedStyle : '']"
          @click="switchTab(tabObject.name)">
          {{ $t(`tabSelector.${tabObject.name}`)}}
      </button>
    </div>
  </div>
  <div class="px-5 text-center rounded-md">
    <div>
      <TransitLiveTab v-if="currentTab == 'live'"/>
      <TransitHistoryTab v-else-if="currentTab == 'history'"/>
      <StatsTab v-else-if="currentTab == 'stats'" />
      <ObjectTab v-else-if="currentTab == 'objects'" />
      <FacilityTab v-else-if="currentTab == 'facility'" />
      <UsersTab v-else-if="currentTab == 'users'" />
    </div>
  </div>
</template>

<script>
import useUserManagementStore from '@/stores/UserManagementStore';
import TransitHistoryTab from './TransitHistoryTab.vue';
import TransitLiveTab from './TransitLiveTab.vue';
import StatsTab from './StatsTab.vue';
import ObjectTab from './ObjectTab.vue';
import FacilityTab from './FacilityTab.vue';
import UsersTab from './UsersTab.vue';
import ToastComponent from './generic/ToastComponent.vue';

export default {
  name: 'TabSelector',
  data() {
    return {
      currentTab: 'live',
      commonStyle: 'inline-block px-5 py-3 border-b-4 border-transparent hover:text-sky-400 hover:border-sky-400',
      selectedStyle: 'text-sky-700 border-sky-700 bg-sky-50',
      tabs: [
        { name: 'live', permission: '' },
        { name: 'history', permission: '' },
        { name: 'objects', permission: '' },
        { name: 'stats', permission: '' },
        { name: 'facility', permission: 'facility_tab' },
        { name: 'users', permission: 'all_users_read' },
      ],
    };
  },
  components: {
    TransitHistoryTab,
    TransitLiveTab,
    StatsTab,
    ObjectTab,
    FacilityTab,
    UsersTab,
    ToastComponent,
  },
  methods: {
    switchTab(mode) {
      this.currentTab = mode;
    },
    showTabIfPermissionGranted(permissionName) {
      if (!permissionName) {
        return true;
      }
      return useUserManagementStore().hasPermissionName(permissionName);
    },
  },
  mounted() {
    this.emitter.on('show-toast', (toastInfo) => {
      this.$refs.globalToast.openToast(toastInfo);
    });
    useUserManagementStore().startRefreshTokenPeriodicJob();
  },
  unmounted() {
    this.emitter.off('show-toast');
    useUserManagementStore().stopRefreshTokenPeriodicJob();
  },
};
</script>
