import { defineStore } from 'pinia';
import statsValidator from '@/validators/statsValidator';
import transitValidator from '@/validators/transitValidator';
import metaObjectValidator from '@/validators/metaObjectValidator';
import { STATISTICAL_TYPE } from '@/utils/lookup';
import {
  getAPIData,
  getUrlData,
  putAPIData,
  postAPIData,
  deleteAPIData,
} from '@/utils/api';
import { apiUrl } from '@/../TransitViewerConfig';
import i18n from '@/locale/i18n';
import emitter from '@/utils/create_emitter';
import useUMStore from './UserManagementStore';

const useTransitStore = defineStore('transitStore', {
  state: () => ({
    facilities: [],
    facility: {},
    locations: {},
    transit: {},
    stats: {},
    statsTypes: {},
    streams: {},
    metaObject: {},
    metaObjectTypes: {},
    errors: {
      fetchStats: null,
      fetchTransits: null,
      fetchMetaObjects: null,
    },
  }),
  actions: {
    async fetchFacilities() {
      try {
        this.facilities = await getAPIData('/facilities', useUMStore().getUserToken());
      } catch (error) {
        console.error(error);
      }
    },
    async fetchFilteredFacilities(filterValues) {
      const queryParams = new URLSearchParams();
      if (filterValues.searchValue) {
        queryParams.append('search_value', filterValues.searchValue);
      }
      try {
        return getAPIData(`/facilities?${queryParams.toString()}`, useUMStore().getUserToken());
      } catch (error) {
        console.error(error);
      }
      return [];
    },
    async fetchLocation() {
      try {
        this.locations = await getAPIData('/locations', useUMStore().getUserToken());
      } catch (error) {
        console.error(error);
      }
    },
    async fetchFilteredLocations(filterValues) {
      const queryParams = new URLSearchParams();
      if (filterValues.searchValue) {
        queryParams.append('search_value', filterValues.searchValue);
      }
      if (filterValues.facilityIds) {
        filterValues.facilityIds.forEach((facilityId) => queryParams.append('facility_id', facilityId));
      }

      try {
        return getAPIData(`/locations?${queryParams.toString()}`, useUMStore().getUserToken());
      } catch (error) {
        console.error(error);
      }
      return [];
    },
    cleanTransits() {
      this.transit = {};
    },
    async fetchTransits({
      sortingType = 'desc',
      sortAttribute = ['end_time'],
      initialTime = 0,
      finishTime = Math.floor(Date.now() / 1000),
      searchValue = '',
      pageNumber = 0,
      pageSize = 50,
      includeEmptyTransits = false,
      locations,
      transitTypes,
      transitDirection,
    } = {}) {
      this.errors = {};
      const queryParams = new URLSearchParams({
        sorting_type: sortingType,
        initial_time: initialTime,
        finish_time: finishTime,
        search_value: searchValue,
        page_number: pageNumber,
        page_size: pageSize,
        include_empty_transits: includeEmptyTransits,
      });
      if (Array.isArray(sortAttribute)) {
        sortAttribute.forEach((attribute) => {
          queryParams.append('sort_attribute', attribute);
        });
      } else {
        queryParams.append('sort_attribute', sortAttribute);
      }
      if (locations) {
        Object.values(locations).forEach((locationId) => queryParams.append('location_id', locationId));
      }
      if (transitTypes) {
        Object.values(transitTypes).forEach((transitType) => queryParams.append('transit_type', transitType));
      }
      if (transitDirection) {
        queryParams.append('direction', transitDirection);
      }
      try {
        this.transit = await getAPIData(`/transits?${queryParams.toString()}`, useUMStore().getUserToken());
        if (this.transit.isNoContent) {
          console.warn('No transits returned from this query.');
        } else {
          transitValidator(this.transit);
        }
      } catch (error) {
        console.error(error);
        this.errors.fetchTransits = i18n.global.t('transitStore.errorFetchTransits');
      }
    },
    async fetchTransitsReport({
      sortingType = 'desc',
      sortAttribute = ['end_time'],
      initialTime = 0,
      finishTime = Math.floor(Date.now() / 1000),
      searchValue = '',
      pageNumber = 0,
      pageSize = 50,
      locations,
      transitTypes,
      reportDict,
    } = {}) {
      this.errors = {};
      const queryParams = new URLSearchParams({
        sorting_type: sortingType,
        initial_time: initialTime,
        finish_time: finishTime,
        search_value: searchValue,
        page_number: pageNumber,
        page_size: pageSize,
        report_type: reportDict.report_type,
      });
      if (Array.isArray(sortAttribute)) {
        sortAttribute.forEach((attribute) => {
          queryParams.append('sort_attribute', attribute);
        });
      } else {
        queryParams.append('sort_attribute', sortAttribute);
      }
      if (locations) {
        Object.values(locations).forEach((locationId) => queryParams.append('location_id', locationId));
      }
      if (transitTypes) {
        Object.values(transitTypes).forEach((transitType) => queryParams.append('transit_type', transitType));
      }
      try {
        const data = await getAPIData(`/transits/report?${queryParams.toString()}`, useUMStore().getUserToken(), {
          headers: {
            Accept: reportDict.header,
          },
          responseType: 'blob',
        });
        return data;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async fetchImage(streamId, imageId) {
      try {
        const data = await getAPIData(`/image/${streamId}/${imageId}`, useUMStore().getUserToken(), {
          headers: {
            Accept: 'image/jpeg',
          },
          responseType: 'blob',
        });
        return URL.createObjectURL(data);
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async fetchClientLogo() {
      try {
        const data = await getUrlData(new URL('client_logo.png', apiUrl()).href, useUMStore().getUserToken(), {
          headers: {
            Accept: 'image/png',
          },
          responseType: 'blob',
        });
        return URL.createObjectURL(data);
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async fetchStatsTypes() {
      const statTypes = await getAPIData('/stats/types', useUMStore().getUserToken());
      this.statsTypes = {};
      statTypes.types?.forEach((statType) => {
        if (statType in STATISTICAL_TYPE) {
          this.statsTypes[statType] = STATISTICAL_TYPE[statType];
        } else console.error('Type', statType, 'does not exist');
      });
    },
    async fetchStats({
      initialTime = 0,
      finishTime = Math.floor(Date.now() / 1000),
      statsTypes = [],
      locations,
    } = {}) {
      const queryParams = new URLSearchParams({
        initial_time: initialTime,
        finish_time: finishTime,
      });
      if (Array.isArray(statsTypes)) {
        statsTypes.forEach((attribute) => {
          queryParams.append('statistical_type', attribute);
        });
      } else {
        queryParams.append('statistical_type', statsTypes);
      }
      if (locations) {
        Object.values(locations).forEach((locationId) => queryParams.append('location_id', locationId));
      }
      try {
        const stats = await getAPIData(`/stats?${queryParams.toString()}`, useUMStore().getUserToken());
        statsValidator(stats);
        this.stats = stats;
        this.errors.fetchStats = null;
      } catch (error) {
        console.error(error);
        this.errors.fetchStats = i18n.global.t('transitStore.errorFetchStats');
      }
    },
    async fetchMetaObjects({
      sortingType = 'desc',
      sortAttribute = ['end_time'],
      initialTime = 0,
      finishTime = Math.floor(Date.now() / 1000),
      searchValue = '',
      metaObjectType = [],
      pageNumber = 0,
      pageSize = 50,
      confidenceRange = [0, 1],
      locations = {},
    } = {}) {
      this.errors = {};
      const queryParams = new URLSearchParams({
        sorting_type: sortingType,
        initial_time: initialTime.valueOf(),
        finish_time: finishTime.valueOf(),
        search_value: searchValue,
        page_number: pageNumber,
        page_size: pageSize,
      });
      if (confidenceRange) {
        queryParams.append('minimum_confidence', confidenceRange[0]);
        queryParams.append('maximum_confidence', confidenceRange[1]);
      }
      if (locations) {
        Object.values(locations).forEach((locationId) => queryParams.append('location_id', locationId));
      }
      if (Array.isArray(sortAttribute)) {
        sortAttribute.forEach((attribute) => {
          queryParams.append('sort_attribute', attribute);
        });
      } else {
        queryParams.append('sort_attribute', sortAttribute);
      }
      if (Array.isArray(metaObjectType) && metaObjectType) {
        metaObjectType.forEach((attribute) => {
          queryParams.append('meta_object_type', attribute);
        });
      } else {
        queryParams.append('meta_object_type', metaObjectType);
      }
      try {
        const result = await getAPIData(`/meta_objects?${queryParams.toString()}`, useUMStore().getUserToken());
        if (result.isNoContent) {
          this.metaObject = { meta_objects: [], count: 0 };
        } else {
          this.metaObject = result;
          metaObjectValidator(this.metaObject);
        }
      } catch (error) {
        console.error(error);
        this.errors.fetchMetaObjects = i18n.global.t('transitStore.errorFetchMetaObjects');
      }
    },
    async fetchMetaObjectTypes() {
      try {
        const metaObjectTypes = await getAPIData('/meta_objects/types', useUMStore().getUserToken());
        this.metaObjectTypes = {};
        metaObjectTypes.types?.forEach((metaObjectType) => {
          if (metaObjectType in STATISTICAL_TYPE) {
            this.metaObjectTypes[metaObjectType] = STATISTICAL_TYPE[metaObjectType];
          } else console.error('Type', metaObjectType, 'does not exist');
        });
      } catch (error) {
        console.error(error);
      }
    },
    async fetchStreams() {
      try {
        this.streams = await getAPIData('/streams', useUMStore().getUserToken());
      } catch (error) {
        console.error(error);
      }
    },
    async fetchFilteredStreams(filterValues) {
      const queryParams = new URLSearchParams();
      if (filterValues.searchValue) {
        queryParams.append('search_value', filterValues.searchValue);
      }
      if (filterValues.locationIds) {
        filterValues.locationIds.forEach((locationId) => queryParams.append('location_id', locationId));
      }

      try {
        return getAPIData(`/streams?${queryParams.toString()}`, useUMStore().getUserToken());
      } catch (error) {
        console.error(error);
      }
      return [];
    },
    async putReadingValue({
      readingId = -1,
      newValue = '',
    } = {}) {
      const queryParams = new URLSearchParams({ new_value: newValue });
      const retValue = putAPIData(`/reading/${readingId}?${queryParams.toString()}`, useUMStore().getUserToken());
      return retValue.isNoContent || retValue === '';
    },
    async putClassificationValue({
      classificationId = -1,
      newValue = '',
    } = {}) {
      const queryParams = new URLSearchParams({ new_value: newValue });
      const retValue = putAPIData(`/classification/${classificationId}?${queryParams.toString()}`, useUMStore().getUserToken());
      return retValue.isNoContent || retValue === '';
    },
    async addFacility(facilityInfo) {
      try {
        const response = await postAPIData('/facilities', useUMStore().getUserToken(), {
          body: JSON.stringify(facilityInfo),
        });
        emitter.emit('updated_facilities');
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async editFacility(facilityInfo) {
      try {
        const facilityUrl = `/facilities/${encodeURIComponent(facilityInfo.id)}`;
        const response = await putAPIData(facilityUrl, useUMStore().getUserToken(), {
          body: JSON.stringify(facilityInfo),
        });
        emitter.emit('updated_facilities');
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async deleteFacility(facilityInfo) {
      try {
        const deleteSuccessfull = await deleteAPIData(`/facilities/${facilityInfo.id}`, useUMStore().getUserToken());
        if (!deleteSuccessfull) {
          return false;
        }
        emitter.emit('updated_facilities');
        return true;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async addLocation(locationInfo) {
      try {
        const response = await postAPIData('/locations', useUMStore().getUserToken(), {
          body: JSON.stringify(locationInfo),
        });
        emitter.emit('updated_locations');
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async editLocation(locationInfo) {
      try {
        const locationUrl = `/locations/${encodeURIComponent(locationInfo.id)}`;
        const response = await putAPIData(locationUrl, useUMStore().getUserToken(), {
          body: JSON.stringify(locationInfo),
        });
        emitter.emit('updated_locations');
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async deleteLocation(locationInfo) {
      try {
        const deleteSuccessfull = await deleteAPIData(`/locations/${locationInfo.id}`, useUMStore().getUserToken());
        if (!deleteSuccessfull) {
          return false;
        }
        emitter.emit('updated_locations');
        return true;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async addSource(sourceInfo) {
      try {
        const response = await postAPIData('/streams', useUMStore().getUserToken(), {
          body: JSON.stringify(sourceInfo),
        });
        if (response.status < 400) {
          emitter.emit('updated_sources');
        }
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async editSource(sourceInfo) {
      try {
        const sourceEndpointUrl = `/streams/${sourceInfo.id}`;
        const response = await putAPIData(sourceEndpointUrl, useUMStore().getUserToken(), {
          body: JSON.stringify(sourceInfo),
        });
        emitter.emit('updated_sources');
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async deleteSource(sourceInfo) {
      try {
        const deleteSuccessfull = await deleteAPIData(`/streams/${sourceInfo.id}`, useUMStore().getUserToken());
        if (!deleteSuccessfull) {
          return false;
        }
        emitter.emit('updated_sources');
        return true;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
});

export default useTransitStore;
